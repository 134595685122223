<template>
  <div class="relative">
    <Swiper
      :modules="[SwiperNavigation]"
      :slides-per-view="itemsPerRowOnMobile"
      :space-between="15"
      class="pb-20"
      :scrollbar="{ el: '.swiper-scrollbar', draggable: true }"
      :breakpoints="{
        '768': {
          slidesPerView: 2.5,
          spaceBetween: 20,
          slidesPerGroup: 4.4,
          slidesOffsetBefore: 0,
        },
        '1024': {
          slidesPerView: itemsPerRowOnDesktop,
          spaceBetween: 20,
          slidesPerGroup: itemsPerRowOnDesktop,
          slidesOffsetBefore: 0,
        },
      }"
    >
      <SwiperSlide v-for="item in items" :key="item.id" class="swiper-slide">
        <slot :item="item" />
      </SwiperSlide>
      <SwiperControls :current-index="currentIndex" />

      <!-- <div class="swiper-scrollbar"></div> -->
    </Swiper>
    <!-- If we need navigation buttons -->
    <template v-if="arrows">
      <button
        v-if="items.length > itemsPerRowOnDesktop"
        class="swiper-nav swiper-button-prev"
        :class="{
          'swiper-nav__inverted': inverted,
        }"
        aria-label="previous slide"
        @click="prevSlide"
      >
        <svg-icon-chevron-left
          class="text-primary-1-100 stroke-current w-4 h-4"
          :class="[$i18n.locale == 'ar' ? 'transform rotate-180' : '']"
        />
      </button>
      <button
        v-if="items.length > itemsPerRowOnDesktop"
        class="swiper-nav swiper-button-next"
        :class="{
          'swiper-nav__inverted': inverted,
        }"
        aria-label="next slide"
        @click="nextSlide"
      >
        <svg-icon-chevron-right
          class="text-primary-1-100 stroke-current w-4 h-4"
          :class="[$i18n.locale == 'ar' ? 'transform rotate-180' : '']"
        />
      </button>
    </template>
  </div>
</template>

<script setup lang="ts">
import { PropType } from 'vue';

const props = defineProps({
  items: {
    type: Array as PropType<ReturnType<typeof mapProductListing>[]>,
    required: true,
  },
  arrows: {
    type: Boolean,
    default: false,
  },
  itemsPerRowOnDesktop: {
    type: Number,
    default: 5,
  },
  itemsPerRowOnMobile: {
    type: Number,
    default: 2.3,
  },
  alignment: {
    type: String,
    default: 'start',
    validator: (value: string) => ['start', 'center', 'end'].includes(value),
  },
  inverted: {
    type: Boolean,
    default: false,
  },
});

const currentIndex = ref(1);

function nextSlide() {
  currentIndex.value = currentIndex.value + 1;
}

function prevSlide() {
  currentIndex.value = currentIndex.value - 1;
}
</script>

<style lang="postcss" scoped>
.swiper-container {
  width: 100%;
  height: 420px;

  @screen md {
    height: 500px;
  }
}

.swiper-nav {
  @apply hidden lg:flex border border-primary-1-100;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &::after {
    content: unset;
  }

  &__inverted {
    @apply border-white;

    svg {
      @apply text-white;
    }
  }

  &.swiper-button-next {
    right: -70px;
  }

  &.swiper-button-prev {
    left: -70px;
  }

  &.swiper-button-disabled {
    opacity: 0.3;
  }
}
</style>
<style>
.swiper-scrollbar-drag {
  background: black !important;
}
</style>
